import { ReactComponent as HappyCloud } from "./assets/happyCloud.svg";
import React, { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import { useNavigate } from "react-router-dom";
import teacherFile from "./md/teachers.md";
import parentFile from "./md/parents.md";

export default function InfoPage({ userType }) {
    const navigate = useNavigate();
    const [markdown, setMarkdown] = useState("");
    const headingText = userType === "teachers" ? "Info for Teachers" : "Info for Parents";
    const file = userType === "teachers" ? teacherFile : parentFile;

    useEffect(() => {
        fetch(file)
            .then((res) => res.text())
            .then((text) => setMarkdown(text));
    }, [file]);

    const handleClick = () => {
        navigate('/');
    };

    return (
        <div className="min-h-screen bg-gradient-to-br from-blue-100 to-indigo-100 p-4 pt-6 fade-in">
            <div className='flex mx-auto text-white rounded-xl pb-4 max-w-5xl'>
                <button onClick={handleClick} className='bg-gray-900 text-white hover:bg-gray-700 font-bold rounded-xl p-2'>Back to game</button>
            </div>
            <div className="flex items-center mx-auto bg-blue-600 text-white rounded-xl shadow-lg p-6 max-w-5xl">
                <HappyCloud className="w-32 h-32 mr-8 flex-shrink-0" />
                <div className="space-y-3">
                    <h1 className="text-4xl font-bold">{headingText}</h1>
                    <p className="text-xl">
                        {userType === "teachers" ? "Here is some info and resources for educators." : "How to help your child use this site."}
                    </p>
                </div>
            </div>
            <div className='prose lg:prose-md m-auto mt-8 p-6 bg-white rounded-xl shadow-lg max-w-5xl text-gray-800'>
                <ReactMarkdown>{markdown}</ReactMarkdown>
            </div>
        </div>
    );
}
