import React, { useState, useEffect } from 'react';
import Bus from './assets/bus.png';
import pollutedImage from './assets/polluted.png';
import busFrame from './assets/busFrameNoBus.png';

const MovingImage = (props) => {
  const [isImageVisible, setImageVisible] = useState(false);
  const [isPollutedVisible, setPollutedVisible] = useState(false);

  useEffect(() => { 
    const timer = setTimeout(() => {
      setImageVisible(true);
    }, 3000);
    const timer2 = setTimeout(() => {
        setPollutedVisible(true);
        setTimeout(() => {
          props.onComplete();
        }, 10000);
    }, 10000);

    return () => {
    clearTimeout(timer);
    clearTimeout(timer2);
  };
  }, []);

  return (
    <div className='max-w-[1151px] overflow-clip m-auto'>
    <div className="relative h-screen">
      <div
        className="absolute inset-0 bg-center bg-cover bg-opacity-50 transition-all aspect-video max-w-[1151px] max-h-[751px]"
        style={{
          backgroundImage: `url(${busFrame})`,
          opacity: !isPollutedVisible ? 1 : 0,
          transition: 'opacity 5s ease-in-out',
        }}
      ></div>
      <div
        className="absolute inset-0 bg-center bg-cover bg-opacity-50 -z-10 transition-all aspect-video max-w-[1151px] max-h-[751px]"
        style={{
          backgroundImage: `url(${pollutedImage})`,
          opacity: isPollutedVisible ? 1 : 0,
          transition: 'opacity 5s ease-in-out',
        }}
      ></div>

      <img
        id="bus-frame"
        className={`absolute h-40 max-w-72 top-[20%] md:top-[28%] lg:bottom-[20%] object-fill animate-drive ${isImageVisible ? '' : 'invisible'}`}
        src={Bus}
        alt="Moving Bus"
      />
    </div>
    </div>

  );
};

export default MovingImage;
