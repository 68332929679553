import * as React from "react";
import { getStroke } from "perfect-freehand";
import { getSvgPathFromStroke } from "../utils";
import "../App.css";
import BusRender from './BusRender'

export default function DrawFrame(props) {
  const [strokes, setStrokes] = React.useState([]);
  const [lastActions, setlastActions] = React.useState([]);
  const [undoneActions, setUndoneActions] = React.useState([]);
  const svgRef = React.useRef(null);
  const [offsetX, setOffsetX] = React.useState(0);
  const [offsetY, setOffsetY] = React.useState(0);

  React.useEffect(() => {
    const svgElement = svgRef.current;
    const { x, y } = svgElement.getBoundingClientRect();
    setOffsetX(x);
    setOffsetY(y);
  }, [strokes]);
  
  React.useEffect(() => {
    if (props.eraseState === "undoStroke") {
      undoStroke();
    } else if (props.eraseState === "redoStroke") {
      redoStroke();
    }
  }, [props.clicked]);

  const handleClick = (event) => {
    if (props.isPaintBucket !== true) return;
    const x = event.clientX;
    const y = event.clientY;

    const targetElement = document.elementFromPoint(x, y);
    const newLastAction = {
      value: strokes[strokes.length - 1],
      x: x,
      y: y,
      prevColor: targetElement.style.fill,
      currentColor: props.color,
      type: 'bucket',
    };
    setlastActions((prev) => {
      prev.push(newLastAction);
      return prev;
    });
    // Change the fill color of the clicked element and its children
    targetElement.style.fill = props.color;
    console.log(lastActions.length)
  };

  function handlePointerDown(e) {
    if (props.isPaintBucket) return;
  
    const svgElement = svgRef.current;
    const svgRect = svgElement.getBoundingClientRect();
  
    // Calculate the initial pointer position relative to the SVG element
    const initialX = e.clientX - svgRect.left;
    const initialY = e.clientY - svgRect.top;
  
    // Check if the initial pointer position is within the bounds of the SVG element
    if (initialX < 0 || initialX > svgRect.width || initialY < 0 || initialY > svgRect.height) {
      return; // Mouse is out of bounds, do nothing
    }
  
    const newLastAction = {
      value: strokes[strokes.length - 1],
      coords: [],
      type: 'draw',
    };
    setlastActions((prev) => [...prev, newLastAction]);
    e.target.setPointerCapture(e.pointerId);
    const newStroke = {
      value: [[initialX, initialY, e.pressure]],
      color: props.color,
    };
    setStrokes((prevStrokes) => [...prevStrokes, newStroke]);
  }
  
  

  function handlePointerMove(e) {
    if (e.buttons !== 1 || props.isPaintBucket) return;

    const isErasing = props.eraseState === "eraseStroke";
    if (isErasing) return; // Do not handle pointer move while erasing
    setStrokes((prevStrokes) => {
      const updatedStrokes = [...prevStrokes];
      const lastStroke = updatedStrokes[updatedStrokes.length - 1];
      lastStroke.value.push([e.pageX - offsetX, e.pageY - offsetY, e.pressure]);
      return updatedStrokes;
    });
  }

  function undoStroke() {
    if (lastActions.length > 0 && props.eraseState === "undoStroke") {
      setlastActions((prev) => {
        const poppedAction = prev.pop();
        if (poppedAction.type === 'draw') {
          setStrokes((prevStrokes) => {
            poppedAction.value = prevStrokes.pop();
            return prevStrokes;
          });
        }
        else { 
          console.log(poppedAction);
          const targetElement = document.elementFromPoint(poppedAction.x, poppedAction.y);
          targetElement.style.fill = poppedAction.prevColor;
        }
        setUndoneActions((undone) => [...undone, poppedAction]);
        return prev;
      })
    }
  }

  function redoStroke() {
    if (undoneActions.length > 0 && props.eraseState === "redoStroke") {
      setUndoneActions((prev) => {
        const poppedAction = prev.pop();
        if (poppedAction.type === 'draw') {
          setStrokes((prevStrokes) => [...prevStrokes, poppedAction.value]);
          const newLastAction = {
            value: poppedAction.value,
            type: 'draw',
          };
          setlastActions((prevLast) => [...prevLast, newLastAction]);        }
        else {
          const targetElement = document.elementFromPoint(poppedAction.x, poppedAction.y);
          targetElement.style.fill = poppedAction.currentColor;
          const newLastAction = {
            x: poppedAction.x,
            y: poppedAction.y,
            prevColor: targetElement.style.fill,
            currentColor: poppedAction.currentColor,
            type: 'bucket',
          };
          setlastActions((prevLast) => [...prevLast, newLastAction]);
        }
        return prev;
      });
    }
  }

  return (
    <div className="flex rounded-xl shadow-md flex-col justify-center items-center h-full w-full max-w-2xl m-auto min-h-[35vh] bg-white">
      <svg
        ref={svgRef}
        className="w-full max-w-2xl h-full min-h-[30vh] bg-white"
        onPointerDown={handlePointerDown}
        onPointerMove={handlePointerMove}
        onClick={handleClick}
        style={{ touchAction: "none" }}
      >
        <BusRender paintBucket={props.isPaintBucket} color={props.color} />
        {strokes && strokes.map((stroke, index) => {
          if (stroke.value !== undefined) {
            const pathData = getSvgPathFromStroke(
              getStroke(stroke.value, {
                size: 7,
                thinning: 0.5,
                smoothing: 0.5,
                streamline: 0.5,
              })
            );
            return <path fill={stroke.color} key={index} d={pathData} />;
          }
        })}
      </svg>
    </div>
  );
}
