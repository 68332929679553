import React, { useEffect, useState } from 'react';
import {ReactComponent as ArrowRight} from './assets/arrow-right.svg';
import ReactMarkdown from "react-markdown";
import Confetti from 'react-confetti';

const CarouselCardPage = ({ title, text, element, audio, onComplete, confetti }) => {
  const [fade, setFade] = useState('fade-in');
  const [markdown, setMarkdown] = useState("");

  // Effect to handle prop changes
  useEffect(() => {
    setFade('fade-in');
  }, [title, text, element, audio]); // Dependencies array

  useEffect(() => {
    setMarkdown(text);
  }, [text]); // Dependencies array

  return (
<div>
      {confetti && <Confetti numberOfPieces={400} recycle={false}></Confetti>}
      <div className={`w-5/6 lg:w-1/2 max-w-2xl m-auto relative py-16 px-12 bg-white rounded-xl mt-32 ${fade}`}>
      <div className="absolute -top-32 -left-32 transform scale-[55%]">
        {element}
      </div>
      <div className="p-3 relative w-11/12">
        <h2 className="text-4xl pb-4 font-bold text-black">{title}</h2>
        <div className="text-lg prose text-black">
          <ReactMarkdown>{markdown}</ReactMarkdown>
        </div>
        {audio && audio}
      </div>
      {title !== "Thanks for helping out on this mission!" &&
            <button
            onClick={() => {
              setFade('fade-out');
              setTimeout(() => {
                onComplete();
              }, 50)
            }}
            className="z-20 absolute top-0 bottom-0 right-8 text-xl font-black text-white bg-orange-500 w-12 h-12 hover:bg-orange-600 m-auto rounded-full"
          >
            <ArrowRight className="m-auto" />
          </button>
      }
    </div>
    </div>
  );
};

export default CarouselCardPage;
