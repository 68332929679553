import React, { useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom'; // Import useLocation
import Home from "./Home";
import CarouselCardPage from "./CarouselCardPage";
import AnimatedScene from "./AnimatedScene";
import SpotTheDifference from "./SpotTheDifference";
import CleanTheAir from "./CleanTheAir";
import DrawManager from "./DrawManager";
import { ReactComponent as ExplainCloud } from "./assets/explainCloud.svg";
import { ReactComponent as HappyCloud } from "./assets/happyCloud.svg";
import { ReactComponent as Badge1 } from "./assets/badge1.svg";
import { ReactComponent as Badge2 } from "./assets/badge2.svg";
import { ReactComponent as Badge3 } from "./assets/badge3.svg";
import AudioButtons from "./components/AudioButtons";
import InfoPage from "./InfoPage";

const modalContent = {
  content: [
    {
      title: "Hey there superhero!",
      text: "Your mission is ready. **Click the next button to get started!**",
      element: "HappyCloud",
    },
    {
      title: "Mission",
      text: "Clouds near the school haven't been feeling well lately. Find out what's causing problems in the air in order to help them feel better!",
      element: "HappyCloud",
    },
    {
      title: "Earn every badge!",
      text: "With every activity successfully completed, you will be able to **earn badges**! Try to win them all.",
      element: "HappyCloud",
    },
    {
      title: "Start",
      activity: "AnimatedScene",
    },
    {
      title: "Oh No!",
      text: "The buses made the cloud feel even more sick!",
      element: "ExplainCloud",
    },
    {
      title: "Idling",
      text: "Idling is when a vehicle is left running while parked. When buses idle, they make air pollution worse.",
      element: "ExplainCloud",
    },
    {
      title: "Find the Difference",
      text: "Find the difference between the two images to win the Idle Reduction Helper badge.",
      element: "ExplainCloud",
    },
    {
      title: "Spot the Difference Activity",
      activity: "SpotTheDifference",
    },
    {
      title: "Great Job!",
      text: "The cloud’s feeling better already, and you just earned the Idle Reduction Helper badge! Let’s learn more about diesel and particulate matter.",
      element: "Badge1",
      confetti: true,
    },
    {
      title: "Diesel",
      text: "Have you ever thought that a bus smelled really stinky? That’s because of **diesel**.",
      element: "ExplainCloud",
    },
    {
      title: "Particulate Matter",
      text: "Diesel exhaust lets out small particles called particulate matter. Particulate matter pollutes the air and is bad to breathe.",
      element: "ExplainCloud",
    },
    {
      title: "Help clear the air!",
      text: "Learn ways to make the environment clean again and earn a new badge.",
      element: "ExplainCloud",
    },
    {
      title: "Clear the Air",
      activity: "CleanTheAir",
    },
    {
      title: "Great Job",
      text: "You earned the **Clean Air Champion** badge and I can finally see the nature! But the mission isn’t done yet... the nature is still sad!",
      element: "Badge2",
      confetti: true,
    },
    {
      title: "Old Diesel",
      text: "Old diesel buses are like the ones that made the cloud feel sick. These buses don’t have a filter to stop bad particles from escaping, which is harmful for our health.",
      element: "ExplainCloud",
    },
    {
      title: "New Diesel",
      text: "New diesel buses are cleaner and don’t have as many emissions.",
      element: "ExplainCloud",
    },
    {
      title: "Electric Buses",
      text: "Electric buses don’t use any diesel at all! They run on a battery and help keep the air clean and protect the Earth by producing zero emissions.",
      element: "ExplainCloud",
    },
    {
      title: "Hear the Difference",
      text: "Electric buses are also much quieter than diesel buses. No more need to shout!",
      element: "HappyCloud",
      audioCard: "AudioButtons",
    },
    {
      title: "Replace diesel buses!",
      text: "Design your own electric bus to replace the diesel buses and make the nature happy again.",
      element: "HappyCloud",
    },
    {
      title: "Draw a Clean Air Bus",
      activity: "DrawManager",
    },
    {
      title: "Amazing Job",
      text: "You earned the **Electric Bus Savior** badge and the environment is healthy again.",
      element: "Badge3",
      confetti: true,
    },
    {
      title: "Are you ready for your next mission?",
      text: "This is the **most important step yet**! Press the next button to learn how you can help keep you and your school healthy.",
      element: "HappyCloud",
    },
    {
      title: "Action Steps",
      text: "**Here are some things that you can do!** \n - Write to your principal and board of education representatives about using cleaner buses!  \n - Tell your family members and teachers what you've learned today! [Here is a pledge you can have a grown-up sign.](https://www.epa.gov/sites/default/files/2019-11/1_pledge_form_for_parents_0.docx) \n - Make sure your bus drivers are not idling!",
      element: "HappyCloud",
    },
    {
      title: "Thanks for helping out on this mission!",
      text: "We can’t wait to see you change the world and make the environment and your schools healthier!",
      element: "HappyCloud",
      confetti: true
    }
  ],
};

const NavigationButtons = () => {
  const navigate = useNavigate();

  return (
    <div className="flex justify-between w-full p-5">
      <button
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        onClick={() => navigate(-1)}
      >
        Back
      </button>

      <div className="space-x-2">
        <button
          className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
          onClick={() => navigate('/')}
        >
          Home
        </button>
        <button
          className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
          onClick={() => navigate('/parents')}
        >
          Parents
        </button>
        <button
          className="bg-orange-500 hover:bg-orange-700 text-white font-bold py-2 px-4 rounded"
          onClick={() => navigate('/teachers')}
        >
          Teachers
        </button>
      </div>
    </div>
  );
};

function App() {
  const location = useLocation(); // Get the current location
  const startingIndex = location.pathname.startsWith('/game/') ? 1 : 0; // Check if the pathname starts with '/game/'

  const [currentIndex, setCurrentIndex] = useState(startingIndex); // Initialize currentIndex based on the condition
  const navigate = useNavigate();
  const onComplete = () => {
    if (currentIndex < modalContent.content.length - 1) {
      const nextItem = modalContent.content[currentIndex + 1];
      const nextPath = `/game/${nextItem.title
        .replace(/\s+/g, "-")
        .toLowerCase()}`;
      setCurrentIndex((prev) => prev + 1);
      navigate(nextPath);
    }
  };
  // Function to dynamically determine the component
  const getComponent = (componentName) => {
    const components = {
      HappyCloud: <HappyCloud />,
      ExplainCloud: <ExplainCloud />,
      Badge1: <Badge1 className='scale-125 relative left-16 top-10' />,
      Badge2: <Badge2 className='scale-125 relative left-16 top-10' />,
      Badge3: <Badge3 className='scale-125 relative left-16 top-10' />,
      AudioButtons: <AudioButtons />,
      AnimatedScene: <AnimatedScene onComplete={onComplete} />,
      SpotTheDifference: <SpotTheDifference onComplete={onComplete} />,
      CleanTheAir: <CleanTheAir onComplete={onComplete} />,
      DrawManager: <DrawManager onComplete={onComplete} />,
    };

    return components[componentName] || null;
  };
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/parents" element={<InfoPage userType="parents" />} />
      <Route path="/teachers" element={<InfoPage userType="teachers" />} />

      {modalContent.content.map((item, index) => {
        const path = `/game/${item.title.replace(/\s+/g, "-").toLowerCase()}`;
        const act = item.activity ? getComponent(item.activity) : null;

        return (
          <Route
            key={index}
            path={path}
            element={
              act != null ? (
                <div className="min-h-screen overflow-x-hidden">
                  <NavigationButtons></NavigationButtons>
                  <div className='mt-10 fade-in'>
                    {act}
                  </div>
                </div>
              ) : (
                <div>
                  <NavigationButtons></NavigationButtons>
                  {item.audioCard ?
                    <CarouselCardPage
                      title={item.title}
                      text={item.text}
                      element={getComponent(item.element)}
                      audio={getComponent(item.audioCard)}
                      onComplete={() => onComplete()}
                      confetti = {item.confetti}
                    /> : <CarouselCardPage
                      title={item.title}
                      text={item.text}
                      element={getComponent(item.element)}
                      onComplete={() => onComplete()}
                      confetti = {item.confetti}
                    />}

                </div>
              )
            }
          />
        );
      })}
    </Routes>
  );
}
export default App;
