import { useNavigate } from "react-router-dom";
export default function Home() {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/game/hey-there-superhero!");
  }

  const handleParents = () => {
    navigate("/parents");
  }
  
  const handleTeachers = () => {
    navigate("/teachers");
  }

  return (
    <div className="bg-gray-100 fade-in grid grid-cols-1 lg:grid-cols-2 min-h-screen">
      <header className="bg-blue-600 py-4 flex items-center justify-center h-full w-full">
        <div className="container mx-auto text-white text-left w-fit space-y-6">
          <h1 className="text-6xl font-black">Clean Air Hero</h1>
          <p className="text-xl mt-2">Come save the air from bus emissions!</p>
          <div className='grid grid-cols-1 md:grid-cols-3 gap-3'>
            <button onClick={handleClick} className='bg-white text-blue-600 hover:bg-gray-100 font-black rounded-xl p-2'>Start playing</button>
            <button onClick={handleTeachers} className='border-white border text-white hover:text-blue-100 font-black rounded-xl p-2'>For teachers</button>
            <button onClick={handleParents} className='border-white border text-white hover:text-blue-100 font-black rounded-xl p-2'>For parents</button>
          </div>
        </div>
      </header>

      <div>
        <div className="w-full h-full">
          <img
            src="images/cleanAirBg.png"
            alt="Sample Image"
            className="w-full h-full object-cover right-10 rounded-lg shadow-md"
          />
        </div>
      </div>
    </div>
  );
}