import React, { useState, useRef } from 'react';
import Volume from '../assets/volume-2.svg';
import Pause from '../assets/pause-circle.svg';

const AudioButtons = () => {
  const [isPlaying1, setIsPlaying1] = useState(false);
  const [isPlaying2, setIsPlaying2] = useState(false);

  const audioRef1 = useRef(null);
  const audioRef2 = useRef(null);

  const handlePlay = (audioRef, setPlaying) => {
    if (audioRef.current) {
      audioRef.current.play();
      setPlaying(true);
    }
  };

  const handlePause = (audioRef, setPlaying) => {
    if (audioRef.current) {
      audioRef.current.pause();
      setPlaying(false);
    }
  };

  return (
    <div className="flex pt-12 -mt-4 flex-row space-x-12">
      <button
        className="w-16 h-16 rounded-full text-black flex flex-col items-center justify-center"
        onClick={() => {
          if (audioRef1.current && audioRef1.current.paused) {
            handlePlay(audioRef1, setIsPlaying1);
          } else {
            handlePause(audioRef1, setIsPlaying1);
          }
        }}
      >
        {isPlaying1 ? (
            <div className='bg-orange-400 p-3 m-auto rounded-full'>
          <img src={Pause} className="m-auto fill-white invert" alt="Pause" />
            </div>
        ) : (
            <div className='bg-orange-400 p-3 m-auto rounded-full'>
          <img src={Volume} className="m-auto fill-white invert" alt="Play" />
          </div>
        )}
        <span className='text-black font-bold text-center'>Diesel</span>
      </button>

      <button
        className="w-16 h-16 rounded-full text-black flex flex-col items-center justify-center"
        onClick={() => {
          const audio = document.getElementById('audio2');
          if (audioRef2.current && audioRef2.current.paused) {
            handlePlay(audioRef2, setIsPlaying2);
          } else {
            handlePause(audioRef2, setIsPlaying2);
          }
        }}
      >
        {isPlaying2 ? (
            <div className='bg-orange-400 p-3 m-auto rounded-full'>
          <img src={Pause} className="m-auto fill-white invert" alt="Pause" />
            </div>
        ) : (
            <div className='bg-orange-400 p-3 m-auto rounded-full'>
          <img src={Volume} className="m-auto fill-white invert" alt="Play" />
          </div>
        )}
        <span className='text-black font-bold text-center'>Electric</span>
      </button>

      <audio ref={audioRef1} id="audio1" src="audio1.mp3" />
      <audio ref={audioRef2} id="audio2" src="audio2.mp3" />
    </div>
  );
};

export default AudioButtons;
