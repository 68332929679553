import './App.css'
import * as React from "react"
import {ReactComponent as GreenBus} from './assets/cleanAir/greenBus.svg';
import {ReactComponent as Idling} from './assets/cleanAir/idling.svg';
import {ReactComponent as Talk} from './assets/cleanAir/talk.svg';
import BusFrame from './assets/cleanAir/frame.svg';
import Modal from 'react-modal';

  const modalContent = {
    content: [
        {
            title: "Electric Buses",
            text: "Changing out diesel buses with electric buses is much better for the environment. With zero emissions, electric buses are the best option for the health of you and nature!",
            element: <GreenBus className='mt-24 transform' />
        },
        {
          title: "Talk to an adult",
          text: "Talk to a parent, teacher, school nurse, or principal about the dangers of bus emissions and idling and how you can help the environment.",
          element: <Talk className='mt-6 scale-[55%]' />
      },
        {
            title: "No Idling",
            text: "By reducing idling it helps to save money and fuel while also preventing extra pollution from entering the environment. Be a part of the no idling team!",
            element: <Idling className='scale-[60%]' />
        },
    ] 
  }

export default function CleanTheAir({onComplete}) {

  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalContentIndex, setModalContentIndex] = React.useState(0);
  const imageRefs = React.useRef([]);
  const [count, setCount] = React.useState(0);
  const [opacity, setOpacity] = React.useState(0.60);
  function openModal(index) {
    setIsOpen(true);
    imageRefs.current[index].classList.add('invisible');
    setModalContentIndex(index);
    setCount(count + 1);
  }

  function afterOpenModal() {
  }

  function closeModal() {
    setIsOpen(false);
    if (count === 3) {
      setTimeout(() => onComplete(), 750);
    }
    setOpacity(prev => prev - 0.20);
  }

return(
<div className='flex flex-col m-auto space-y-4 fade-in'>
<Modal
        className='w 2/3 md:w-1/2 lg:w-1/3 h-5/6 m-auto flex items-center justify-center'
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
      >
        <div
        className='relative py-[4.25rem] px-12 bg-white rounded-xl shadow-xl'>
          <div className='absolute top-0 left-0 transform -translate-x-32 -translate-y-44 scale-[55%]'>
            {modalContent.content[modalContentIndex].element}
          </div>
        <h2 className='text-3xl pb-4 font-bold text-black'>{modalContent.content[modalContentIndex].title}</h2>
        <button className='z-20 absolute right-4 top-4 text-xl font-black text-white bg-orange-500 w-12 h-12 hover:bg-orange-600 m-auto rounded-full' onClick={closeModal}>X</button>
        <div >{modalContent.content[modalContentIndex].text}</div>
        </div>
       
      </Modal>
      <div className='flex w-full h-full items-center justify-center'>
      <div className="relative">
    {/* Your SvgComponent */}
    <img src={BusFrame} className='m-auto object-fill h-full' />
   <div className={`w-full absolute inset-0`} style={{backgroundColor: `rgb(0, 0, 0, ${opacity})`}}>
    </div>
    <div className="absolute -top-[1%] left-[0%] scale-[.4] lg:scale-50">
    {/* SVG image for top left corner */}
    <button onClick={() => openModal(0)} ref={(el) => (imageRefs.current[0] = el)}>
    <GreenBus />
    </button>
  </div>
  <div className="absolute -bottom-[5%] left-[0%] transform scale-[.4] lg:scale-50">
    {/* SVG image for bottom left corner */}
    <button onClick={() => openModal(1)} ref={(el) => (imageRefs.current[1] = el)}>
    <Talk />
    </button>
  </div>
  <div className="absolute -bottom-[5%] right-[0%] transform scale-[.3] lg:scale-50">
    {/* SVG image for bottom right corner */}
    <button onClick={() => openModal(2)} ref={(el) => (imageRefs.current[2] = el)}>
    <Idling />
    </button>
  </div>
  </div>
      </div>

</div>


      

    )
}