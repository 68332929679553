import * as React from "react";
import { getStroke } from "perfect-freehand";
import { getSvgPathFromStroke } from "./utils";
import "./App.css";
import DrawFrame from './components/DrawFrame'

export default function DrawManager(props) {
  const [selectedColor, setSelectedColor] = React.useState('#000');
  const [isPaintBucket, setIsPaintBucket] = React.useState(false);
  const [clicked, setClicked] = React.useState(false);
  const [eraseState, setEraseState] = React.useState('');

  const colors = [
    { name: "Red", value: "rgb(239, 68, 68)" },
    { name: "Orange", value: "rgb(246, 133, 27)" },
    { name: "Yellow", value: "rgb(252, 211, 77)" },
    { name: "Green", value: "rgb(66, 183, 98)" },
    { name: "Blue", value: "rgb(66, 153, 225)" },
    { name: "Indigo", value: "rgb(79, 70, 229)" },
    { name: "Purple", value: "rgb(147, 101, 184)" },
    { name: "Pink", value: "rgb(232, 119, 158)" },
    { name: "Teal", value: "rgb(64, 186, 176)" },
    { name: "Gray", value: "rgb(156, 163, 175)" },
    { name: "Black", value: "rgb(0, 0, 0)" },
    { name: "White", value: "rgb(255, 255, 255)" },
    // Add more colors as needed
  ];

  const handleColorSelect = (color) => {
    setSelectedColor(color.value);
  };

  const handleUndo = async () => {
    setClicked(true);
    await setEraseState('undoStroke');
    setEraseState('')
    setClicked(false);
  };

  const handleRedo = async () => {
    setClicked(true);
    await setEraseState('redoStroke');
    setEraseState('')
    setClicked(false);
    };

  const handlePaintBucket = () => {
    setIsPaintBucket(!isPaintBucket);
  };

  const ColorManager = () => {
    return (
      <div className="flex bg-gray-100 rounded-xl shadow-md justify-center w-5/6 overflow-scroll m-auto items-center space-x-2 p-4">
        {/* Color Pills */}
        <div className="flex items-center space-x-4">
          {colors.map((color, index) => (
            <button
              key={index}
              className={`w-10 h-10 rounded-full border-2 shadow-md border-gray-900 `}
              style={{ backgroundColor: color.value }}
              onClick={() => handleColorSelect(color)}
            ></button>
          ))}
        </div>
      </div>
    );
  };

  const StateManager = () => {
    return (
      <div className="flex justify-center items-center space-x-2 p-2">
        {/* Color Pills */}
        {/* Paint Bucket Button */}
        <button
          onClick={handlePaintBucket}
          className="bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded-full"
        >
          Fill
        </button>
        {/* Undo Button */}
        <button
          className="bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded-full"
          onClick={handleUndo}
        >
          Undo
        </button>
        {/* Redo Button */}
        <button
          className="bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded-full"
          onClick={handleRedo}
        >
          Redo
        </button>
      </div>
    );
  };

  return (
    <div className='w-full h-full max-w-2xl m-auto flex flex-col justify-center space-y-4 fade-in'>
      <ColorManager />
      <div className='h-3/4'>
        <DrawFrame clicked={clicked} eraseState={eraseState} color={selectedColor} isPaintBucket={isPaintBucket} />
      </div>
      <StateManager />
      <button
          className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-full m-auto w-1/2"
          onClick={props.onComplete}
        >
          Done
        </button>
    </div>
  );
}
